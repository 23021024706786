<template>
  <div id="landing-page-container" class="landing-page-container brindo-aquaclub brindo-terms">
    <main class="landing-page-main">
      <header class="header">
        <img class="logo-brindo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo-brindo.png" alt="Brindo" title="Brindo" />
        <img class="logo-brindo-small" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo-brindo-small.png" alt="Brindo" title="Brindo" />
        <div class="links-wrap">
          <p>
            <a href="#" class="scroll-to-footer hide-mobile">Contact us</a>
            <span class="spacer hide-mobile">|</span>

            <a class="light link lang" href="/brindo-aquaclub/termes">Français</a>
          </p>
          <a href="#" class="cta-wrap js-open-modal">
            <p>Buy your tickets</p>
          </a>
        </div>
      </header>

      <section class="brindo-terms-wrap">
        <h2 class="medium-title">Validity</h2>
        <ul>
          <li>One ticket entitles you to access the waterplay area or to the fitness room for a 3-hour block.</li>
          <li>Youth 12 years and under must be accompanied by an adult of 18 years and over for waterplay area.</li>
          <li>Users of the fitness room must be 16 years and over. Photo ID card required to access the fitness room.</li>
        </ul>
        <h2 class="medium-title">Purchase</h2>
        <ul>
          <li>Can be purchased at all times on site at the Brind’O Aquaclub counter.</li>
          <li>
            Can be purchased 48 hours in advance at any time online or by phone at 1-888-738-1777 except between February 15 to 20, February 29 to March 7
            and March 14 to 21, 2020.
          </li>
          <li>
            If you wish to use the Brind’O Aquaclub facilities on these dates, please make your way to the Brind’O Aquaclub counter to get your access.
          </li>
          <li>To get a free toddler ticket (2 years and under), please make your way to the Brind’O Aquaclub counter to pick up a bracelet.</li>
          <li>Reservations available for groups of 20 people and more. Please call at 1-888-681-5978.</li>
        </ul>
        <h2 class="medium-title">Delivery</h2>
        <ul>
          <li>With lodging: Tickets are delivered directly to your hôtel. Please show your ticket at the counter.</li>
          <li>Without lodging: Please pick up your bracelet directly at the Brind’O Aquaclub counter upon presentation of your purchase confirmation.</li>
        </ul>
        <h2 class="medium-title">Refund</h2>
        <p>If purchased in advance:</p>
        <ul>
          <li>Up to 72 hours prior to your activity: you can have your tickets refunded on credit card.</li>
          <li>After 72 hours prior to your activity: credit loaded on a Tremblant gift card incurs a $25 fee (includes royalty and taxes).</li>
        </ul>
        <h2 class="medium-title">Restrictions</h2>
        <ul>
          <li>Non-transferable product (cannot be transferred from one person to another).</li>
        </ul>
        <h2 class="medium-title">Rules</h2>
        <ul>
          <li>To access the slides, children 12 years and under must be accompanied by an adult 18 years and over.</li>
          <li>Wearing a swimsuit is mandatory to access waterplay area.</li>
          <li>Swim diapers are mandatory for children 3 years and under.</li>
          <li>It is mandatory to take a shower before accessing the waterplay area.</li>
          <li>Sandals or water shoes are recommended in the waterplay area.</li>
          <li>Access to the outdoor hot tub is reserved for adults (18 years and over).</li>
          <li>Access to the fitness room is reserved for 16 years and over.</li>
          <li>No alcoholic beverages allowed on site.</li>
          <li>No glass containers accepted on site.</li>
          <li>Food and non-alcoholic beverages permitted in the food-specific area only.</li>
          <li>Flotation devices are not allowed in the slides. Individual flotation jackets accepted in the outdoor pool and indoor hot tub.</li>
          <li>Access is prohibited to any person who presents the following symptoms:</li>
          <ul>
            <li>Fever</li>
            <li>Diarrhea or nausea</li>
            <li>Contagious skin infection</li>
            <li>Open wounds</li>
          </ul>
          <li>Any incident or problem that could affect water quality must be reported to the supervision attendant on duty.</li>
        </ul>
      </section>

      <div class="bottom-section">
        <img class="logo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo.png" alt="Logo Tremblant" title="Logo Tremblant" />
        <div class="links">
          <p>Tremblant © 2020</p>
          <p class="spacer">|</p>

          <a href="https://www.tremblant.ca/things-to-do/activities/brindo-fitness-room?sc_lang=en" target="_blank">FAQ</a>
          <p class="spacer">|</p>
          <a
            href="https://www.alterramtnco.com/privacy-policy/?webSyncID=1ca4a145-a1b8-e476-8288-d47c79060022&amp;sessionGUID=ae1ad0e3-9738-d616-d467-035a725c2730"
            target="_blank"
            >Privacy Policy</a
          >
          <p class="spacer">|</p>
          <a
            href="https://www.alterramtnco.com/terms-of-use/?webSyncID=1ca4a145-a1b8-e476-8288-d47c79060022&amp;sessionGUID=ae1ad0e3-9738-d616-d467-035a725c2730"
            target="_blank"
            >Terms of Service</a
          >
        </div>
      </div>
    </main>

    <!--[if lt IE 9]>
      <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
      <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
    <![endif]-->
  </div>
</template>

<script>
export default {
  name: 'Brindo',

  head() {
    return {
      title: {
        inner: 'Brind’O Aquaclub | Tremblant',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: 'Brind’O Aquaclub | Tremblant' },
        {
          name: 'description',
          content: 'An aquatic world of epic proportions for the whole family',
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: 'Brind’O Aquaclub | Tremblant' },
        { name: 'twitter:card', content: 'Brind’O Aquaclub | Tremblant' },
        {
          n: 'twitter:description',
          c: 'An aquatic world of epic proportions for the whole family',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Brind’O Aquaclub | Tremblant' },
        { property: 'og:site_name', content: 'Brind’O Aquaclub | Tremblant' },
        {
          property: 'og:description',
          content: 'An aquatic world of epic proportions for the whole family',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://tremblant-promo-demo.jnv.dev/files/meta/_1200x630_fit_center-center_none/slider-6.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://tremblant-promo-demo.jnv.dev/files/meta/_1200x630_fit_center-center_none/slider-6.jpg',
        },
      ],
      link: [{ rel: 'stylesheet', href: '/legacy/site.combined.min.css' }],
      script: [{ type: 'text/javascript', src: '/legacy/site.combined.min.js', body: true }],
    }
  },

  mounted() {
    setTimeout(() => {
      window.site.App.init({ env: 'local', csrf: '', locale: 'fr', device: 'desktop', preview: false, general: { googleMaps: '' } })
    }, 755)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
